import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import {
    ACTIVITY_SUBJECT,
    CATEGORY,
    DATE,
    DESCRIPTION,
    DESCRIPTION2,
    DYNAMIC_FIELD_CODE,
    LOCATION,
    NAME,
    SHORT_DESCRIPTION,
    SIGN_UP,
    TIME,
    TITLE,
    UNIT,
    setOpacityOnHide,
    unitHasCurrentlyActiveContract,
} from "../../../components/utils";
import { AccommodationType, Resort, Unit, getMxtsEnv } from "../../mxts";
import { Activity, ResourceActivitiesDetailsWithDynamicField } from "../../page/activityPlanner/activityPlanner.types";
import { ApiCallOptions, DynamicFieldDataResponse, DynamicFieldInfo, Image, Resort as MXTSResort, MxtsApi, Resource, ResourceActivitiesSubjectResponse } from "@maxxton/cms-mxts-api";
import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { Content, getContent, isContentFilterChanged } from "../../../utils/content.util";
import { DATE_FORMAT, MXTS } from "../../../utils/constants";
import { DispatchOpenLinkedTabOptions, getDynamicBookLink, setPageViewEvent } from "../resultsPanelUtil";
import { DynamicFieldLink, withDynamicField } from "../../../utils/withDynamicField";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { setCRPActivityArea, setCRPActivityCategory, setCRPActivityDynamicField, setLocalActivityTime } from "../../page/activityPlanner/activityPlanner.util";

import { ActivityDynamicField } from "../../page/activityPlanner/ActivityDynamicField";
import { ActivityInfo } from "../../page/activityPlanner/activityPlanner.enum";
import { ActivityLocation } from "../../page/activityPlanner/activityLocation";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Badge } from "reactstrap";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FontTextStyle } from "../../../utils/font.util";
import { Loader } from "../../../components/Loader";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { ResultsPanelAction } from "../../../redux/actions/resultsPanelAction";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { UserInterfaceState } from "../../../redux/reducers/userInterfaceReducer";
import { WidgetOptions } from "./";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { getCustomerIdsFromLoginToken } from "../../../redux/reducers/myEnv/myEnv.util";
import { getImages } from "../../../components/media/mxts-image-gallery/mxts";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getOverallDescriptions } from "../../dynamic/description/description.utils";
import namespaceList from "../../../i18n/namespaceList";

interface DescriptionProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
    unit?: Unit;
    activity?: Activity;
    resort?: MXTSResort;
    unitBookUri?: string;
    dynamicFilter?: DynamicFilter;
    amenityCodes?: string[];
    dispatchOpenLinkedTabAction?: (options: DispatchOpenLinkedTabOptions) => void;
    dynamicContainerOptions?: DynamicContainerOptions;
    className?: string;
    link: ConfiguredLink;
    warmupState?: DescriptionState;
    dynamicFieldLink?: DynamicFieldLink;
    getDynamicFieldLink?: (content: Content, usedForLinking?: boolean) => Promise<void>;
}

interface DescriptionState {
    descriptions: DescriptionResult[];
    dynamicFieldsDescriptions: DescriptionResult[];
    fallbackDescriptions: DescriptionResult[];
    dynamicFieldsFallbackDescriptions: DescriptionResult[];
    isLoading: boolean;
    content: Exclude<Content, Resort[]>;
    env: ApiCallOptions | undefined;
    isInactiveContractUnit?: boolean;
}

export interface DescriptionResult {
    code?: string;
    imageUrl?: string;
    imageAlt?: string;
    type: number | string;
    value: string | Record<string, string> | Activity | DynamicFieldDataResponse[] | ResourceActivitiesSubjectResponse[];
}

export interface CombineDescriptions {
    descriptionMultiSelector?: NumberMultiSelectOption[];
    descriptions: DescriptionResult[];
    dynamicFieldsDescriptions: DescriptionResult[];
    selectedDynamicFieldCodes?: NumberMultiSelectOption[];
    showAllInventoryList?: boolean;
}

interface ResultsPanelDescriptionStoreProps {
    dynamicFilter: DynamicFilter;
    userInterfaceState: UserInterfaceState;
    myEnvState: MyEnvState;
    availabilityState: AvailabilityState;
}

interface ResultsPanelDescriptionDispatchProps {
    dispatchAction: Dispatch<ResultsPanelAction>;
}

type UnitOrAccommodationType = AccommodationType | Unit;

type ResultsPanelDescriptionProps = DescriptionProps & ResultsPanelDescriptionDispatchProps & ResultsPanelDescriptionStoreProps;

export class ResultsPanelDescriptionBase extends React.Component<ResultsPanelDescriptionProps, DescriptionState> {
    private controller: AbortController = new AbortController();

    public static async warmupCache(props: ResultsPanelDescriptionProps): Promise<DescriptionState> {
        return warmupState(props, ResultsPanelDescriptionBase.defaultState(props), async (stateHandler) => {
            ResultsPanelDescriptionBase.loadDescriptions(props, stateHandler);
        });
    }

    private static defaultState(props: ResultsPanelDescriptionProps): DescriptionState {
        return {
            descriptions: [],
            dynamicFieldsDescriptions: [],
            fallbackDescriptions: [],
            dynamicFieldsFallbackDescriptions: [],
            isLoading: true,
            content: undefined,
            env: undefined,
        };
    }

    constructor(props: ResultsPanelDescriptionProps) {
        super(props);
        this.state = {
            ...ResultsPanelDescriptionBase.defaultState(props),
            ...(props.warmupState || {}),
        };
    }

    componentDidMount() {
        const {
            unit,
            options: { contentType },
        } = this.props;
        ResultsPanelDescriptionBase.loadDescriptions(this.props, this, this);
        if (unit && contentType === UNIT) {
            this.setInactiveContractUnit(unit.unitId);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ResultsPanelDescriptionProps) {
        if (
            (this.props.unit && this.props.unit.unitId !== nextProps.unit!.unitId) ||
            (this.props.userInterfaceState &&
                nextProps.userInterfaceState &&
                this.props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType !== nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType) ||
            isContentFilterChanged(this.props, nextProps, false)
        ) {
            this.controller.abort();
            this.controller = new AbortController();
            ResultsPanelDescriptionBase.loadDescriptions(this.props, this, this);
        }
    }

    private async setInactiveContractUnit(unitId: number) {
        const { context } = this.props;
        const env = await getMxtsEnv(context);
        const customerIds = await getCustomerIdsFromLoginToken();
        if (customerIds.length) {
            const unitContracts = await MxtsApi.getContracts(env, { size: MXTS.MAX_RESULTS, unitIds: [unitId], ownerIds: customerIds });
            this.setState({ isInactiveContractUnit: !unitHasCurrentlyActiveContract(unitContracts.content) });
        }
    }

    public componentWillUnmount() {
        this.controller.abort();
    }
    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { options, context, className, accommodationType, unit, unitBookUri, dynamicFilter, amenityCodes, link, activity, dynamicFieldLink } = this.props;
        const { isLoading, content, isInactiveContractUnit } = this.state;
        const overallDescriptions = getOverallDescriptions(this.props, this.state);
        const isDynamicLinkEnabled = options?.linking?.useDynamicFieldAsLink;
        const bookUrl = isDynamicLinkEnabled
            ? dynamicFieldLink?.url || ""
            : UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, accommodationType, unitBookUri, dynamicFilter, amenityCodes);
        const callSetViewEvent = () => {
            setPageViewEvent(context, bookUrl, accommodationType, unit);
        };
        const resourceId =
            Array.isArray(dynamicFilter.resourceid) && dynamicFilter.resourceid.length === 1
                ? dynamicFilter.resourceid[0]
                : !Array.isArray(dynamicFilter.resourceid)
                ? dynamicFilter.resourceid
                : undefined;
        const { currentLocale, site } = context;
        const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, textTitleColor, changeDefaultActivityAreaLabel, changeDefaultActivitySignUpAreaLabel } = options;
        const hideWidget = setOpacityOnHide(options);
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const params: UrlLinkParams = dynamicFilter ? UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter) : {};
        const addResourceIdParams = resourceId || accommodationType?.resourceId || (content as Unit)?.resourceId;
        const addUnitIdParams = dynamicFilter?.unitid || (content as Unit)?.unitId;
        const dynamicBookUrl = isDynamicLinkEnabled ? dynamicFieldLink?.url : getDynamicBookLink(params, link, addResourceIdParams, addUnitIdParams);
        const target = isDynamicLinkEnabled ? dynamicFieldLink?.target : "_blank";
        const enabledDynamicLink = options?.enableBookingsEngineLink || isDynamicLinkEnabled;
        if (isLoading) {
            return (
                <div className={classNames("result-desc", `${options.contentType}__description ${hideWidget}`)}>
                    <Loader type="description" />
                </div>
            );
        }
        return overallDescriptions?.length && content ? (
            <div className={classNames("result-desc", `${hideWidget}`, className, { ["cursor-pointer"]: !!options?.tabLink })} onClick={this.handleDispatchOpenLinkedTabAction}>
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${textTitleColor?.includes("theme") && `color-${textTitleColor}`}`)}
                    style={classNames(textTitleColor?.includes("rgba") && textTitleColor)}
                />
                {/* eslint-disable-next-line max-lines-per-function */}
                {overallDescriptions?.map((descriptionInfo, index) => {
                    const description = descriptionInfo.value || "";
                    switch (descriptionInfo.type) {
                        case TITLE:
                            return enabledDynamicLink ? (
                                <SmartLink
                                    key={index}
                                    href={bookUrl}
                                    target={dynamicFieldLink?.target}
                                    className={classNames("type-name space-m-0", getTitleClassName(options))}
                                    style={getTitleStyle(options)}
                                >
                                    {description as string}
                                </SmartLink>
                            ) : (
                                <h6 key={index} className={classNames("type-description", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {description as string}
                                </h6>
                            );
                        case DESCRIPTION: {
                            return (description as string).includes("</") ? (
                                <span
                                    key={index}
                                    className={classNames("type-description", getTitleClassName(options))}
                                    style={getTitleStyle(options)}
                                    dangerouslySetInnerHTML={{
                                        __html: description as string,
                                    }}
                                />
                            ) : (
                                <span key={index} className={classNames("type-description", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {enabledDynamicLink ? (
                                        <SmartLink href={bookUrl} target={target} rel="noreferrer" onClick={callSetViewEvent}>
                                            {description as string}
                                        </SmartLink>
                                    ) : (
                                        (description as string)
                                    )}
                                </span>
                            );
                        }
                        case DESCRIPTION2: {
                            return !(description as string).includes("</") ? (
                                <span key={index} className={classNames("type-description", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {enabledDynamicLink ? (
                                        <SmartLink href={bookUrl} target={target} rel="noreferrer" onClick={callSetViewEvent}>
                                            {description as string}
                                        </SmartLink>
                                    ) : (
                                        (description as string)
                                    )}
                                </span>
                            ) : (
                                <span
                                    key={index}
                                    className={classNames("type-description2", getTitleClassName(options))}
                                    style={getTitleStyle(options)}
                                    dangerouslySetInnerHTML={{
                                        __html: description as string,
                                    }}
                                />
                            );
                        }
                        case SHORT_DESCRIPTION: {
                            return !(description as string).includes("</") ? (
                                <span key={index} className={classNames("type-description space-m-0", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {enabledDynamicLink ? (
                                        <SmartLink href={bookUrl} target={target} rel="noreferrer" onClick={callSetViewEvent}>
                                            {description as string}
                                        </SmartLink>
                                    ) : (
                                        (description as string)
                                    )}
                                </span>
                            ) : (
                                <span
                                    key={index}
                                    className={classNames("type-short-description space-m-0", getTitleClassName(options))}
                                    style={getTitleStyle(options)}
                                    dangerouslySetInnerHTML={{
                                        __html: description as string,
                                    }}
                                />
                            );
                        }
                        case TIME: {
                            const startTime = (description as Record<string, string>).startTime;
                            const endTime = (description as Record<string, string>).endTime;
                            if (startTime && endTime && !activity?.showMainActivity) {
                                // Don't show time for main activity in CRP
                                return enabledDynamicLink ? (
                                    <SmartLink
                                        key={index}
                                        href={bookUrl}
                                        target={dynamicFieldLink?.target}
                                        className={classNames("time activity-item", getTitleClassName(options))}
                                        style={getTitleStyle(options)}
                                    >
                                        {descriptionInfo.type === TIME && <FontAwesome name="clock-o mr-1" />}
                                        {activity?.isFullDayActivity ? getI18nLocaleString(namespaceList.admin, "fullDay", currentLocale, site) : `${startTime} - ${endTime}`}{" "}
                                    </SmartLink>
                                ) : (
                                    <div key={index} className={classNames("time activity-item", getTitleClassName(options))} style={getTitleStyle(options)}>
                                        {descriptionInfo.type === TIME && <FontAwesome name="clock-o mr-1" />}
                                        {activity?.isFullDayActivity ? getI18nLocaleString(namespaceList.admin, "fullDay", currentLocale, site) : `${startTime} - ${endTime}`}
                                    </div>
                                );
                            }
                        }
                        case LOCATION:
                            return (
                                <ActivityLocation
                                    key={index}
                                    className={classNames(getTitleClassName(options))} // not in use due to activity file conflicts
                                    style={getTitleStyle(options)}
                                    activityAreaResources={(description as Activity).activityAreaResources?.filter((activityAreaResource) => activityAreaResource.type === ActivityInfo.LOCATION)}
                                    locationKey={changeDefaultActivityAreaLabel && getLocalizedContent({ site, currentLocale, localizedContent: changeDefaultActivityAreaLabel })?.activityAreaText}
                                    dynamicFieldLink={dynamicFieldLink}
                                />
                            );
                        case SIGN_UP:
                            return (
                                <ActivityLocation
                                    key={index}
                                    className={classNames(getTitleClassName(options))}
                                    style={getTitleStyle(options)}
                                    activityAreaResources={(description as Activity).activityAreaResources.filter((activityAreaResource) => activityAreaResource.type === ActivityInfo.SIGN_UP)}
                                    locationKey={
                                        changeDefaultActivitySignUpAreaLabel &&
                                        getLocalizedContent({ site, currentLocale, localizedContent: changeDefaultActivitySignUpAreaLabel })?.activitySignUpAreaText
                                    }
                                />
                            );
                        case DATE:
                            return (
                                <div key={index} className={classNames("date activity-item", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {descriptionInfo.type === DATE ? <FontAwesome name="calendar-o mr-1" /> : null}
                                    {moment(description as string).format(DATE_FORMAT.LOCALE_DATE)}
                                </div>
                            );
                        case DYNAMIC_FIELD_CODE: {
                            if (activity && (description as DynamicFieldDataResponse[]).length) {
                                return (description as DynamicFieldDataResponse[]).map((dynamicField, index) => (
                                    <div className="dynamic-field activity-item" key={index}>
                                        <ActivityDynamicField className="activity-dynamic-field" dynamicField={dynamicField.value} />
                                    </div>
                                ));
                            }
                            return (
                                <span key={index} className={classNames("type-dynamicFieldcode", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {enabledDynamicLink ? (
                                        <SmartLink href={bookUrl} target={target} rel="noreferrer" onClick={callSetViewEvent}>
                                            {this.renderDynamicFieldDescription(descriptionInfo)}
                                        </SmartLink>
                                    ) : options.useAsDynamicBookUrlLink ? (
                                        <SmartLink href={dynamicBookUrl} target={target} rel="noreferrer" onClick={callSetViewEvent}>
                                            {this.renderDynamicFieldDescription(descriptionInfo)}
                                        </SmartLink>
                                    ) : (
                                        this.renderDynamicFieldDescription(descriptionInfo)
                                    )}
                                </span>
                            );
                        }
                        case NAME: {
                            const href = isDynamicLinkEnabled ? dynamicFieldLink?.url : options.useAsDynamicBookUrlLink ? dynamicBookUrl : getUrlWithAnchor(link);
                            return !(description as string).includes("</") ? (
                                link.url || dynamicFieldLink ? (
                                    <SmartLink
                                        key={index}
                                        href={href}
                                        target={link.target || dynamicFieldLink?.target}
                                        className={classNames("type-name space-m-0", getTitleClassName(options))}
                                        style={getTitleStyle(options)}
                                    >
                                        {description as string}
                                    </SmartLink>
                                ) : (
                                    <span key={index} className={classNames("type-name space-m-0", getTitleClassName(options))} style={getTitleStyle(options)}>
                                        {options.enableBookingsEngineLink ? (
                                            <SmartLink href={bookUrl} target="_blank" rel="noreferrer" onClick={callSetViewEvent}>
                                                {description as string}
                                            </SmartLink>
                                        ) : (
                                            (description as string)
                                        )}
                                        {!!isInactiveContractUnit && (
                                            <Badge className="inactive-contract" pill>
                                                {getI18nLocaleString(namespaceList.ownerUnitPicker, "inactiveContract", currentLocale, site)}
                                            </Badge>
                                        )}
                                    </span>
                                )
                            ) : (
                                <span
                                    key={index}
                                    className={classNames("type-name space-m-0", getTitleClassName(options))}
                                    style={getTitleStyle(options)}
                                    dangerouslySetInnerHTML={{
                                        __html: description as string,
                                    }}
                                />
                            );
                        }
                        case CATEGORY:
                            return (
                                <div className={classNames("category activity-item", getTitleClassName(options))} style={getTitleStyle(options)}>
                                    {description as string}
                                </div>
                            );
                        case ACTIVITY_SUBJECT:
                            return (description as ResourceActivitiesSubjectResponse[]).map((linkedCustomSubject: ResourceActivitiesSubjectResponse, index: number) =>
                                linkedCustomSubject?.name ? (
                                    <div key={index} className={classNames("activity-subject", getTitleClassName(options))} style={getTitleStyle(options)}>
                                        <label className="activity-subject__name">{linkedCustomSubject?.name}</label>
                                    </div>
                                ) : null
                            );
                    }
                })}
            </div>
        ) : null;
    }
    /* jscpd:ignore-end */

    public renderDynamicFieldDescription = (description: DescriptionResult): JSX.Element => {
        const { imageAlt, imageUrl } = description;
        const value = description.value as string;
        const { options } = this.props;
        if (imageUrl) {
            return (
                <figure className="dynamic-field__image-wrapper">
                    <img loading="lazy" src={imageUrl} alt={imageAlt} />
                </figure>
            );
        }
        if (value?.includes("</") || value?.includes("/>")) {
            // TODO: create a regex to check HTML in sting. MCMS-3815
            return <span dangerouslySetInnerHTML={{ __html: value }} />;
        }
        return (
            <div className="value-wrapper">
                <span className="value-wrapper__value">{value}</span>
                {options.showCopyToClipBoardButton && (
                    <button onClick={() => navigator?.clipboard?.writeText?.(value as string)}>
                        <FontAwesome name="clone" />
                    </button>
                )}
            </div>
        );
    };

    private handleDispatchOpenLinkedTabAction = (): void => {
        const { dispatchOpenLinkedTabAction, options } = this.props;
        if (typeof dispatchOpenLinkedTabAction === "function") {
            dispatchOpenLinkedTabAction(options);
        }
    };

    private static mapDescriptions(state: DescriptionState, props: any) {
        return (descriptionType: NumberMultiSelectOption): DescriptionResult | null => {
            const { content } = state;
            if (content) {
                switch (descriptionType.value) {
                    case NAME: {
                        return {
                            value: props.activity
                                ? props.activity.showMainActivity || props.activity.showMainActivityName
                                    ? (content as Activity).resourceActivity.resortActivity.title || ""
                                    : (content as Activity).resourceActivity.name || ""
                                : (content as Resort | Resource | Unit).name,
                            type: NAME,
                        };
                    }
                    case TITLE: {
                        return {
                            value: props.activity
                                ? props.activity.showMainActivity || props.activity.showMainActivityName
                                    ? (content as Activity).resourceActivity.resortActivity.title || ""
                                    : (content as Activity).resourceActivity.name || ""
                                : "",
                            type: TITLE,
                        };
                    }
                    case DESCRIPTION: {
                        return {
                            value: props.activity
                                ? props.activity.showMainActivity
                                    ? (content as Activity).resourceActivity.resortActivity.description || ""
                                    : (content as Activity).resourceActivity.description || ""
                                : (content as Resort | Resource | Unit).description,
                            type: DESCRIPTION,
                        };
                    }
                    case SHORT_DESCRIPTION: {
                        return {
                            value: props.activity ? (content as Activity).resourceActivity.shortDescription || "" : (content as Resort | Resource | Unit).shortDescription,
                            type: SHORT_DESCRIPTION,
                        };
                    }
                    case DESCRIPTION2: {
                        return {
                            value: props.activity ? (content as Activity).resourceActivity.description2 || "" : (content as Resource | Unit).description2,
                            type: DESCRIPTION2,
                        };
                    }
                    // These below keys are mainly for the activity planner
                    case TIME: {
                        return {
                            value: { startTime: (content as Activity).startTime, endTime: (content as Activity).endTime },
                            type: TIME,
                        };
                    }
                    case LOCATION: {
                        return {
                            value: props.activity && content,
                            type: LOCATION,
                        };
                    }
                    case SIGN_UP: {
                        return {
                            value: props.activity && content,
                            type: SIGN_UP,
                        };
                    }
                    case DATE: {
                        return {
                            value: props.activity.day,
                            type: DATE,
                        };
                    }
                    case DYNAMIC_FIELD_CODE: {
                        return {
                            value: props.activity ? (content as ResourceActivitiesDetailsWithDynamicField).dynamicFieldData || [] : "",
                            type: DYNAMIC_FIELD_CODE,
                        };
                    }
                    case CATEGORY: {
                        return {
                            value: props.activity ? (content as ResourceActivitiesDetailsWithDynamicField).activityCategory || [] : "",
                            type: CATEGORY,
                        };
                    }
                    case ACTIVITY_SUBJECT: {
                        return {
                            value: props.activity ? (content as ResourceActivitiesDetailsWithDynamicField).linkedCustomSubjects || [] : "",
                            type: ACTIVITY_SUBJECT,
                        };
                    }
                }
            }
            return null;
        };
    }

    private static async fetchDynamicFieldCode(
        dynamicFieldOption: NumberMultiSelectOption,
        props: ResultsPanelDescriptionProps,
        stateHandler: StateHandler<ResultsPanelDescriptionProps, DescriptionState>,
        tthis?: ResultsPanelDescriptionBase
    ): Promise<DynamicFieldInfo | Image | null | undefined> {
        const { context } = props;
        const { content, env } = stateHandler.state;
        const managerId = (content as UnitOrAccommodationType)?.dynamicManagerId;
        try {
            if (env && managerId) {
                const code = `${dynamicFieldOption.value}`;
                const widgetOptionsDynamicFieldCodesPaths: Array<keyof WidgetOptions> = ["dynamicFieldFallback", "dynamicFieldCodes"];
                const dynamicFieldResponse = await context.mxtsApi.dynamicFieldsInfoCustomized(
                    env,
                    {
                        managerId,
                        code,
                        checkFallback: false,
                        widgetOptionsId: props.options._id,
                        widgetOptionsDynamicFieldCodesPaths,
                    },
                    undefined,
                    tthis?.controller.signal
                );
                if (dynamicFieldResponse.length) {
                    const { imageManagerId, name } = dynamicFieldResponse[0];
                    if (imageManagerId) {
                        const imageResponse = await getImages(context, imageManagerId);
                        return { ...imageResponse[0], code, name };
                    }
                }
                return dynamicFieldResponse[0];
            }
            return null;
        } catch (error) {
            if (!(error instanceof DOMException && error.code === DOMException.ABORT_ERR)) {
                context.logger.error(error);
            }
        }
    }

    private static setDynamicFieldCodes(
        dynamicFieldInfo: DynamicFieldInfo | Image | null,
        useFallbackDescription = false,
        stateHandler: StateHandler<ResultsPanelDescriptionProps, DescriptionState>
    ): void {
        if (dynamicFieldInfo) {
            const { code, name, urls, value } = dynamicFieldInfo as DynamicFieldInfo & Image;
            const imageUrl = urls?.large.replace("t_newyse_large", "t_mcms_larger/f_auto");
            if (value || imageUrl) {
                const descriptions: DescriptionResult = { code, imageAlt: name, imageUrl, value, type: DYNAMIC_FIELD_CODE };
                if (useFallbackDescription) {
                    return stateHandler.setState(({ dynamicFieldsFallbackDescriptions }) => ({
                        dynamicFieldsFallbackDescriptions: [...dynamicFieldsFallbackDescriptions, descriptions],
                    }));
                }
                return stateHandler.setState(({ dynamicFieldsDescriptions }) => ({
                    dynamicFieldsDescriptions: [...dynamicFieldsDescriptions, descriptions],
                }));
            }
        }
    }

    private static async handleDynamicFieldCodes(
        props: ResultsPanelDescriptionProps,
        stateHandler: StateHandler<ResultsPanelDescriptionProps, DescriptionState>,
        tthis?: ResultsPanelDescriptionBase,
        useFallbackDescription = false
    ): Promise<void> {
        const {
            options: { dynamicFieldCodes, dynamicFieldFallback },
        } = props;
        const descriptions = useFallbackDescription ? dynamicFieldFallback : dynamicFieldCodes;
        if (descriptions) {
            await Promise.all(
                descriptions.map((field) =>
                    ResultsPanelDescriptionBase.fetchDynamicFieldCode(field, props, stateHandler, tthis).then((response) =>
                        ResultsPanelDescriptionBase.setDynamicFieldCodes(response!, useFallbackDescription, stateHandler)
                    )
                )
            );
        }
    }

    private static async handleDescriptions(
        props: ResultsPanelDescriptionProps,
        stateHandler: StateHandler<ResultsPanelDescriptionProps, DescriptionState>,
        tthis?: ResultsPanelDescriptionBase
    ): Promise<void> {
        const { options } = props;

        if (options.dynamicFieldCodes?.length) {
            this.handleDynamicFieldCodes(props, stateHandler, tthis);
        }
        if (options.descriptionTypeMultiSelector) {
            const descriptionsInfo = options.descriptionTypeMultiSelector.map(this.mapDescriptions(stateHandler.state, props));
            const descriptions = descriptionsInfo.filter((desc) => desc);
            if (descriptions?.length) {
                stateHandler.setState({ descriptions: descriptions as DescriptionResult[] });
            }
        }
        if (options.fallbackDescription && options.dynamicFieldFallback) {
            this.handleDynamicFieldCodes(props, stateHandler, tthis, true);
        }
        if (options.fallbackDescription && options.descriptionMultiSelectorFallback) {
            const fallbackDescriptions = options.descriptionMultiSelectorFallback.map(this.mapDescriptions(stateHandler.state, props)).filter((desc) => desc);
            if (fallbackDescriptions?.length) {
                stateHandler.setState({ fallbackDescriptions: fallbackDescriptions as DescriptionResult[] });
            }
        }
        stateHandler.setState({ isLoading: false });
    }

    private static async loadDescriptions(
        props: ResultsPanelDescriptionProps,
        stateHandler: StateHandler<ResultsPanelDescriptionProps, DescriptionState>,
        tthis?: ResultsPanelDescriptionBase
    ): Promise<void> {
        let content = (await getContent({ ...props, prioritizeResultPanelParams: true })) as Exclude<Content, Resort[]>;
        const env = await getMxtsEnv(props.context, props.context.currentLocale.code);
        let selectedOptions = props.options?.descriptionTypeMultiSelector || [];

        if (props.options?.linking?.useDynamicFieldAsLink) {
            selectedOptions = [
                ...selectedOptions,
                {
                    value: DYNAMIC_FIELD_CODE,
                },
            ];
        }

        stateHandler.setState({ env });
        if (props.activity) {
            let newContent = cloneDeep(content);
            newContent = await setCRPActivityArea(newContent as Activity, selectedOptions, env);
            // Set dynamic fields
            newContent = await setCRPActivityDynamicField({
                activity: newContent,
                selectedOptions,
                env,
                widgetOptionsId: props.options._id,
                context: props.context,
            });
            newContent = await setCRPActivityCategory(newContent as Activity, props.context, env);
            if (newContent.startTime.includes("Z") || newContent.endTime.includes("Z")) {
                newContent = await setLocalActivityTime(newContent as Activity, props.context);
            }
            content = newContent;
        }
        if (props.options?.linking?.useDynamicFieldAsLink && !props.dynamicFieldLink && props?.getDynamicFieldLink) {
            props.getDynamicFieldLink(content, true);
        }
        stateHandler.setState({ content }, () => ResultsPanelDescriptionBase.handleDescriptions(props, stateHandler, tthis));
    }
}

function getTitleClassName(options: WidgetOptions) {
    return classNames(options.fontType, options.fontStyle, {
        [`color-${options.fontColor}`]: options.fontColor?.includes("theme"),
        [`border-width-${options.borderWidth}`]: !(options.fontStyle === FontTextStyle.BACKGROUND_COLOR) && options.fontStyle === FontTextStyle.BORDER_LINE,
        [`border-color-${options.nameTextUndrlinedColor}`]: !(options.fontStyle === FontTextStyle.BACKGROUND_COLOR) && options.nameTextUndrlinedColor?.includes("theme"),
        [`background-color-${options.fontBackgroundColor}`]: !(options.fontStyle === FontTextStyle.BORDER_LINE) && options.fontBackgroundColor?.includes("theme"),
    });
}

const getTitleStyle = (options: WidgetOptions) => {
    const styleAttributes = {
        background: options.fontStyle !== "default" && options.fontStyle !== FontTextStyle.BORDER_LINE && options.fontBackgroundColor?.includes("rgba") ? options.fontBackgroundColor : undefined,
        borderColor:
            options.fontStyle !== "default" && options.fontStyle !== FontTextStyle.BACKGROUND_COLOR && options.nameTextUndrlinedColor?.includes("rgba") ? options.nameTextUndrlinedColor : undefined,
        color: options.fontColor?.includes("rgba") ? options.fontColor : undefined,
    };
    return styleAttributes;
};

function mapStateToProps(state: State): ResultsPanelDescriptionStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        myEnvState: state.myEnvState,
        availabilityState: state.availabilityState,
        userInterfaceState: state.userInterfaceState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<ResultsPanelAction>): ResultsPanelDescriptionDispatchProps {
    return { dispatchAction: dispatch };
}

export const ResultsPanelDescription = withDynamicField(
    wrapProps<DescriptionProps>(connect<ResultsPanelDescriptionStoreProps, ResultsPanelDescriptionDispatchProps>(mapStateToProps, mapDispatchToProps)(ResultsPanelDescriptionBase))
);
