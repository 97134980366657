import * as React from "react";

import { Content } from "./content.util";
import { retrieveLinkFromDynamicField } from "../plugins/resultsPanel/button/resultsPanelButton.util";

export interface DynamicFieldLink {
    url?: string;
    target?: string;
}

export const withDynamicField = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: any) => {
    const [dynamicFieldLink, setDynamicFieldLink] = React.useState<DynamicFieldLink>();

    const getDynamicFieldLink = async (content: Content | Content[], usedForLinking?: boolean) => {
        const generatedLink = await retrieveLinkFromDynamicField(props, content, usedForLinking);
        setDynamicFieldLink(generatedLink);
    };

    return <WrappedComponent {...(props as any)} dynamicFieldLink={dynamicFieldLink} getDynamicFieldLink={getDynamicFieldLink} />;
};
