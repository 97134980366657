import * as React from "react";

import { DYNAMIC_FIELD_CODE, LOCATION, NAME, SIGN_UP, descriptionMultiSelectorFallback, descriptionTypeMultiSelector, dynamicFieldPrice, getContentTypeSelector } from "../../../components/utils";
import { FontTextStyle, getFontTypeSelector } from "../../../utils/font.util";
import { FormSpec, getHelpId, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { MultiSelectOptionNumberValue, WithId } from "@maxxton/cms-api";
import { ResultsPanelDescription, ResultsPanelDescriptionBase } from "./ResultsPanelDescription";
import { dynamicFieldMultiSelectorFallback, tabLinkAutocomplete } from "../../../inputSpecs";
import { getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";

import { ActivityLocationOptions } from "../../page/activityPlanner/activityPlanner.types";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { TypesearchContainerWidgetSpec } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getActivityLocationArea } from "../../page/activityPlanner/activityPlanner.util";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions, WithId, ActivityLocationOptions {
    fontType?: string;
    fontStyle: string;
    fontColor: string;
    textTitleColor?: string;
    fontBackgroundColor: string;
    borderWidth: string;
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    styleIds: string[];
    descriptionTypeMultiSelector?: NumberMultiSelectOption[];
    showDynamicField?: boolean;
    dynamicFieldCodes?: NumberMultiSelectOption[];
    descFontSize: string;
    descFontColor: string;
    descFontStyle: string;
    nameTextUndrlinedColor: string;
    fallbackDescription?: boolean;
    descriptionMultiSelectorFallback?: NumberMultiSelectOption[];
    dynamicFieldFallback?: NumberMultiSelectOption[];
    enableBookingsEngineLink: boolean;
    tabLink?: string;
    contentTypeSelector?: boolean;
    toggleButton: boolean;
    ElementToToggle: string;
    linking: LinkingSpecOptions;
    useAsDynamicBookUrlLink: boolean;
    showCopyToClipBoardButton?: boolean;
    showActivityDynamicField?: boolean;
}

const TARGETS = ["description"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "description-widget-options",
    name: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    help: getHelpId("peaSe3-general"),
                    properties: [
                        [
                            ...getContentTypeSelector(),
                            descriptionTypeMultiSelector("descriptionTypeMultiSelector"),
                            {
                                variable: "showActivityDynamicField",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "showActivityDynamicField"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE),
                            },
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes", undefined, undefined, "Activity"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE) && !!options.showActivityDynamicField,
                            },
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE) && !options.showActivityDynamicField,
                            },
                            ...getActivityLocationArea<WidgetOptions>(
                                (options: WidgetOptions) => !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === LOCATION),
                                (options: WidgetOptions) => !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === SIGN_UP)
                            ),
                            {
                                variable: "showCopyToClipBoardButton",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "showCopyToClipBoardButton"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE),
                            },
                            {
                                variable: "fallbackDescription",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "fallbackDescription"),
                                type: "checkbox",
                            },
                            descriptionMultiSelectorFallback("descriptionMultiSelectorFallback"),
                            dynamicFieldMultiSelectorFallback("dynamicFieldFallback"),
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                visible: (options: WidgetOptions): boolean => options.toggleButton,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            tabLinkAutocomplete("tabLink"),
                            {
                                variable: "enableBookingsEngineLink",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "enableBookingsEngineLink"),
                                type: "checkbox",
                            },
                            {
                                variable: "useAsDynamicBookUrlLink",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsDynamicBookUrlLink"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find(
                                        (check: MultiSelectOptionNumberValue) =>
                                            check.value === NAME || !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE)
                                    ),
                            },
                            linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking", visible: (options: WidgetOptions) => !options.enableBookingsEngineLink }, true),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...getFontTypeSelector(),
                            {
                                variable: "nameTextUndrlinedColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "borderLineColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => options.fontStyle === FontTextStyle.BORDER_LINE,
                            },
                            {
                                variable: "descFontStyle",
                                label: getI18nLocaleObject(namespaceList.admin, "descFontStyle"),
                                type: "select",
                                default: "",
                                optionList: [
                                    {
                                        value: "text-uppercase",
                                        label: getI18nLocaleObject(namespaceList.admin, "allCaps"),
                                    },
                                    {
                                        value: "text-capitalize",
                                        label: getI18nLocaleObject(namespaceList.admin, "camelCase"),
                                    },
                                    {
                                        value: "text-lowercase",
                                        label: getI18nLocaleObject(namespaceList.admin, "lowerCase"),
                                    },
                                ],
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const resultsPanelDescriptionWidget: TypesearchContainerWidgetSpec<WidgetOptions> = {
    id: "resultsPanelDescriptionWidget",
    type: "resultsPanel",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidget"),
    description: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        descFontSize: "default-font-size",
        descFontColor: "font-default",
        descFontStyle: "",
        fontBackgroundColor: "background-gray",
        borderWidth: "1",
        nameTextUndrlinedColor: "font-default",
        enableBookingsEngineLink: false,
        contentTypeSelector: false,
        toggleButton: false,
        ElementToToggle: "",
        fontStyle: "default",
        fontColor: "font-default",
        styleIds: [],
        linking: linkingSpecDefaultValues,
        useAsDynamicBookUrlLink: false,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const link = await getLinkFromLinkingSpec({ linkingSpecOptions: widget.options.linking, context });
        if (isServerSide()) {
            await ResultsPanelDescriptionBase.warmupCache({
                options: widget.options,
                context,
                className: styleClasses,
                link,
                ...resultOptions,
                dispatchAction: context.reduxStore.store.dispatch,
                userInterfaceState: {},
                availabilityState: {},
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                myEnvState: {},
            });
        }
        return <ResultsPanelDescription dynamicContainerOptions={dynamicContainerOptions} options={widget.options} className={styleClasses} context={context} link={link} />;
    },
};
